.navbar-vertical.navbar-expand-xs .navbar-collapse {
  display: block;
  overflow: hidden!important;
  height: calc(100vh - 360px);
}

.card.card-background {
  align-items: center;
  display: none !important;
}

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
  display: none !important;
}

/* .fas.editOrUpdate{
  font-weight: 900;
  margin-left: 21px;
} */

.ant-pagination-next, .ant-pagination-prev {
  vertical-align: unset !important;
}

.badge.phone{
  font-size: larger;
}

.namePhoneSearch {
  min-width: 336px;
}

.fa-pen-to-square:hover, .fa-trash:hover, .fa-eye:hover {
  transform: scale(1.2);
  cursor: pointer;
  border-color: green;
}

input.ant-select-selection-search-input:focus {
  /*border-color: #007eff;*/
  border-color: none ! important;
  /* box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px rgba(0, 126, 255, 0.1); */
  /* outline: none; */
}

.reloadCustomer:hover{
    height: 17px;
    width: 17px;
    color: green;
    cursor: pointer;
    /* top: 24px; */
}
.reloadCustomer{
  transform: translateY(50%);
}

.alert-dismissible .btn-close {
  position: absolute;
  top: calc(100% - 75px) !important;
  right: 0;
  z-index: 2;
  padding: 1.25rem 1rem;
}

.customer p {
  margin-bottom: 2px;
}

.react-datepicker-wrapper {
  width: 100%;
}

/*Very important Here i erase the style because when we display the component search of antd in a modal of boostrap the element of search are hide*/
.ant-select-dropdown {
  z-index: 1056;
}

.navbar-vertical.navbar-expand-xs .navbar-collapse {
  display: block;
  overflow: hidden !important;
  height: calc(100vh - 129px) !important;
}

.react-datepicker-popper{
  z-index: 9999 !important;
}
